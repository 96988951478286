
.titleText{
    font-size: 16px;
    padding-left: 10px;
}

.contentWrapper{
    font-size: 16px;
    text-align: center;
}

.button{
    border-radius: 10px;
    margin: 20px auto;
}

.link{
    border: 1px solid;
    padding: 10px 20px;
    width: auto;
    border-radius: 10px;
}