.closeBtn {
	background-color: transparent;
	border: none;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	width: 100%;
	padding: 10px 20px !important;
	max-width: 360px;
	margin: 0 auto;
	padding-inline-start: 0 !important;
}

.closeBtn span {
	display: flex;
	justify-content: flex-start;
	gap: 10px;
}

.title {
	font-weight: 900;
	font-size: 20px;
	line-height: 34px;
	text-align: center;
	/* Black 100 */
	color: #263038;
	flex: 1;
}

@media (max-width: 360px) {
	.closeBtn {
		padding-inline-start: 20px !important;
	}
}
