:root {
	--font-family: Nunito;

	--font-size-xs: 11px;
	--font-size-sm: 13px;
	--font-size-md: 14px;
	--font-size-md-2: 15px;
	--font-size-lg: 20px;
	--font-size-lg-0: 22px;
	--font-size-lg-1: 24px;

	--font-light: 100;
	--font-normal: 400;
	--font-medium: 500;
	--font-semibold: 600;
	--font-bold: 700;
	--font-bolder: 900;

	--primary-color: #fea419;
	--primary-light-color: #f7fbf6;
	--secondary-color: #587dee;
	--red-color: #f55c47;
	--dark-color: #263238;
	--warm-blue-color: #575d62;
	--gray-blue-color: #bdc0c3;
	--gray-0-color: #ababab;
	--gray-1-color: #bcbcbc;
	--gray-2-color: #cfcfcf;
	--gray-3-color: #e0e0e0;
	--gray-4-color: #e8e8e8;
	--gray-5-color: #f7f7f7;
	--gray-6-color: #edefec;
	--white-color: #ffffff;
	--black-color: #000000;

	--primary-color-rgb: 89, 179, 106;
	--primary-light-color-rgb: 247, 251, 246;
	--secondary-color-rgb: 88, 125, 238;
	--red-color-rgb: 245, 92, 71;
	--dark-color-rgb: 37, 49, 55;
	--warm-blue-color-rgb: 87, 93, 98;
	--gray-blue-color-rgb: 189, 192, 195;
	--gray-0-color-rgb: 171, 171, 171;
	--gray-1-color-rgb: 188, 188, 188;
	--gray-2-color-rgb: 207, 207, 207;
	--gray-3-color-rgb: 224, 224, 224;
	--gray-4-color-rgb: 232, 232, 232;
	--gray-5-color-rgb: 247, 247, 247;
	--gray-6-color-rgb: 237, 239, 236;
	--white-color-rgb: 255, 255, 255;
	--black-color-rgb: 0, 0, 0;

	--border-radius: 10px;
	--border-radius-md: 8px;
	--border-radius-circle: 50%;

	--spacing-xs: 5px;
	--spacing-sm: 10px;
	--spacing-md: 15px;
	--spacing-base: 20px;
	--spacing-lg-0: 25px;
	--spacing-lg-1: 30px;
	--spacing-lg-2: 35px;
	--spacing-lg-3: 40px;
}

body {
	margin: 0;
	font-family: "Nunito", sans-serif !important;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-size: 16px;
	overflow-x: hidden;
}

html,
body,
#root {
	overscroll-behavior-y: none;
	width: 100%;
	height: 100%;
	overflow: auto;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}
