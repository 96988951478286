.loadingRoot {
	background: transparent;
	position: fixed;
	left: calc(50% - 100px);
	top: calc(50% - 120px);
}

.content {
	flex: 1;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.loadingText {
	color: var(--adm-color-primary);
}

.placeholder {
	color: var(--adm-color-primary);
	height: 120px;
	width: 120px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.img {
	height: 120px;
	width: 120px;
}
