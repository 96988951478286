.container {
	flex-direction: column;
	width: 100%;
	height: 100vh;
	max-width: 360px;
	margin: 0 auto;
	position: relative;
	justify-content: space-between;
}

.startWrap {
	justify-content: center;
	width: 100%;
	position: relative;
	flex-direction: column;
	align-items: center;
}

.footer {
	padding-bottom: 20px;
	flex-direction: row;
	gap: 20px;
	display: flex;
}

.btn {
	background: #fea419;
	border-radius: 10px;
	border: none;
	color: #ffffff;
}

.btn.video {
	background-color: #263038;
}

.title {
	font-weight: 900;
	font-size: 20px;
	line-height: 34px;
	text-align: center;
	/* Black 100 */
	color: #263038;
	margin-top: -25px;
}

.description {
	font-weight: 600;
	font-size: 16px;
	line-height: 19px;
	text-align: center;
	/* Gray 100 */
	color: #969696;
	margin-top: 5px;
	line-height: 34px;
}

.btnDateRange {
	font-weight: 900 !important;
	font-size: 14px !important;
	line-height: 19px !important;
	display: flex;
	align-items: center;
	text-align: center;
	color: #ffffff;
	padding: 10px 20px;
	/* Black */
	background: #000000;
	border-radius: 10px;
	margin-top: 15px;
}

@media (max-width: 360px) {
	.container {
		padding-left: 20px;
		padding-right: 20px;
	}
}
