.root > span {
	display: flex;
	align-items: center;
	justify-content: center;
}

.root.primary {
	--background-color: var(--primary-color) !important;
	--border-color: transparent !important;
}

.root.large {
	font-size: 16px;
	font-weight: 700;
	border-radius: 10px;
	padding: 13px 15px;
}

.root.middle {
	font-size: 14px;
	font-weight: 500;
}

.root.middle {
	border-radius: 10px;
}

.iconWrapper {
	height: 22px;
}

.root.danger {
	--background-color: var(--red-color) !important;
	--border-color: transparent !important;
}

.danger.hasShadow {
	box-shadow: 0px 4px 15px rgba(245, 92, 71, 0.4);
}

.info {
	background: #587dee;

	color: #ffffff;
	border: none !important;
}

.info.hasShadow {
	box-shadow: 0px 6px 20px rgba(88, 125, 238, 0.4);
}
