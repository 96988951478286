.text{
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
}

.title{
    font-weight: 800;
    font-size: 16px;
    line-height: 22px; 
}