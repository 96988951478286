.root {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	width: 100%;
	z-index: 1;
}

.gap {
	height: 70px;
	width: 100%;
}

.gapBig {
	height: 110px;
	width: 100%;
}

.gapSm {
	height: 60px;
	width: 100%;
}

.nav {
	font-weight: 600;
	font-size: 18px;
	color: #263238;
	padding: 25px 20px 10px;
}

.arrow {
	padding-top: 6px;
	padding-right: 15px;
}

.arrowRtl {
	padding-right: 0;
	padding-left: 15px;
}

.topHeader {
	display: flex;
	justify-content: space-between;
	align-items: center;
	background: rgba(255, 255, 255, 0.9);
	backdrop-filter: blur(20px);
	height: 55px;
	padding-left: 20px;
	padding-right: 20px;
}

.menuHamburger {
	padding: 10px 10px 10px 0px;
	height: 44px;
}

.avatar {
	height: 36px;
}

.gradientNav {
	background: linear-gradient(
		180deg,
		#ffffff 0%,
		rgba(255, 255, 255, 0) 100%
	);
	color: #263238;
	font-weight: 600;
	font-size: 15px;
	padding: 0 20px;
}

.normalNav {
	background: #ffffff;
	position: relative;
}

.title {
	font-weight: 700;
	font-size: 20px;
	line-height: 28px;
	/* Black 100 */
	color: #263038;
}

.popup {
	width: 80vw;
	border-radius: 0px 20px 20px 0px;
}

.popupRtl {
	border-radius: 20px 0px 0px 20px;
}

.logo {
	margin-top: 15px;
}

.menuHamburgerRtl {
	transform: rotate(180deg);
}

.startPart {
	display: flex;
	align-items: center;
}
