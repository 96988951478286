.root {
    position: fixed;
    bottom: 0;
    width: 100%;
    left: 0;
    right: 0;
    background: #FFFFFF;
    height: 60px;
    padding-top: 0px;
    z-index: 1;
    box-shadow: 0px -5px 15px rgba(0, 0, 0, 0.03);
    border-radius: 10px 10px 0px 0px;
}