@import "antd/dist/antd.css";

html {
	overflow-x: hidden;
}

body.ios-app::-webkit-scrollbar,
html.ios-app::-webkit-scrollbar,
#root.ios-app::-webkit-scrollbar {
	display: none;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	/*background-color: #fff;
  display: flex;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: white;*/
}

.adm-nav-bar-title {
	font-size: 16px !important;
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(360deg);
	}
}

.App {
	margin: 0 auto;
	max-width: 1024px;
}

img {
	width: 100%;
	height: 100%;
	object-fit: contain;
}

.progessBar {
	height: 1rem;
	width: 0%;
	background-color: rgb(68, 212, 231);
	color: white;
	padding: 2px;
}

.file-upload {
	box-shadow: 2px 2px 2px 2px #ccc;
	padding: 2rem;
	display: flex;
	flex-direction: column;

	justify-content: space-between;
	font-size: 1rem;
}

.upbutton {
	width: 5rem;
	padding: 0.5rem;
	background-color: #2767e9;
	color: aliceblue;
	font-size: 1rem;
	cursor: pointer;
}

.ant-btn.btn-success-outline {
	border-color: #52c41a;
}

.app-normal-gutter {
	padding: 20px;
}

.single-item-icon-tool {
	font-size: 20px;
	cursor: pointer;
}

.ant-list-item-meta-title,
.ant-modal-body,
.media-mode-btn-container button,
.speaking-sentence,
.order-game-sentence,
.writing-sentence,
.media-input-spell {
	font-size: 18px;
	color: #2264e9;
}

.speaking-sentence {
	padding: 40px 0 20px;
}

.media-mode-btn-container {
	margin-bottom: 15px;
}

.order-game-tools,
.writing-tools {
	padding: 30px 0 30px;
}

.disorderly-sentence-words .ant-space {
	flex-wrap: wrap;
}

.disorderly-sentence-words .ant-space button {
	margin-bottom: 9px;
}

.order-game-answer-box {
	background-color: #f3f3f3;
	margin-bottom: 20px;
	padding: 20px;
	border: 1px solid #ccc;
	border-radius: 2px;
}

.adm-list {
	--border-bottom: none !important;
	--border-top: none !important;
}

.adm-list-body {
	overflow: inherit !important;
}

.ql-bubble .ql-picker.ql-expanded .ql-picker-options {
	left: -2px !important;
	width: 295px !important;
}

.ql-bubble .ql-picker.ql-expanded.ql-background .ql-picker-options {
	left: -30px !important;
}

.ql-bubble .ql-color-picker .ql-picker-item {
	height: 31px !important;
	width: 31px !important;
}

.ql-toolbar.ql-snow {
	position: fixed;
	bottom: 0;
	background: #fff;
	width: 100%;
	left: 0;
	text-align: center;
	z-index: 101;

	height: 50px;

	border-top: 1px solid #e8e8e8 !important;
	border-radius: 8px 8px 0px 0px;
	border-bottom: none !important;
	border-left: none !important;
	border-right: none !important;
}

.ql-snow .ql-stroke {
	stroke: var(--warm-blue-color) !important;
	stroke-width: 1.2 !important;
}

.ql-toolbar.ql-snow .ql-formats {
	margin-right: 0 !important;
}

.ql-snow .ql-picker.ql-expanded .ql-picker-options {
	top: -95% !important;
	width: 100%;
	left: 0;
	text-align: center;
	border: 1px solid rgba(var(--gray-4-color-rgb), 0.64) !important;
	box-shadow: none !important;
	padding: 6px;
	border-radius: 8px !important;
}

.ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label {
	border-color: rgba(var(--gray-4-color-rgb), 0.64) !important;
	border-radius: 8px !important;
}

.ql-snow .ql-color-picker .ql-picker-item[data-value="white"] {
	border-color: rgba(var(--gray-4-color-rgb), 0.64) !important;
}

.ql-snow .ql-picker-options .ql-picker-item {
	border-radius: 8px !important;
}

.ql-snow .ql-picker {
	position: initial !important;
}

.ql-snow.ql-toolbar button,
.ql-snow .ql-toolbar button {
	height: 32px !important;
	padding: 3px 8px !important;
	width: 42px !important;
}

.ql-snow.ql-toolbar button.ql-size,
.ql-snow .ql-toolbar button.ql-size {
	color: #000;
	font-size: 20px;
	font-weight: bold !important;
	height: auto !important;
	width: auto !important;
}

.ql-snow .ql-color-picker,
.ql-snow .ql-icon-picker {
	width: 38px !important;
	height: 32px !important;
}

.ql-snow .ql-color-picker .ql-picker-item {
	height: 28px !important;
	width: 28px !important;
}

.ql-snow.ql-container {
	height: initial !important;
	font-family: "Nunito", sans-serif;
	background: #ffffff;
	border: 1px solid #e8e8e8 !important;
	box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.02);
	border-radius: 10px;
}

/*.ql-snow.ql-container:focus, 
.ql-snow.ql-container:active{
  border: 1px solid #58B368 !important;

  box-shadow: 0px 5px 10px rgba(88, 179, 104, 0.2) !important;
}*/

.ql-snow .ql-editor {
	padding: 9px 18px !important;
	min-height: 2.5em;
	line-height: 24px !important;
	color: #575d62;
	font-size: 15px;
	font-weight: 500;
}

.ql-snow .ql-fill,
.ql-snow .ql-stroke.ql-fill {
	fill: var(--warm-blue-color) !important;
}

.ql-editor.ql-blank::before {
	color: var(--adm-color-light) !important;
	font-style: normal !important;
	font-size: 14px !important;
	left: 18px !important;
	right: 0 !important;
}

.adm-form-item.adm-form-item-vertical .adm-form-item-label {
	font-weight: 500 !important;
	font-size: 13px !important;
	color: #bcbcbc !important;
	margin-bottom: 10px !important;
}

.adm-space-horizontal.adm-space-wrap > .adm-space-item {
	--gap-vertical: 8px;
	padding-bottom: var(--gap-vertical);
}

.adm-space-horizontal > .adm-space-item {
	--gap-horizontal: 8px;
	margin-right: var(--gap-horizontal) !important;
}

.adm-image-uploader-cell {
	border-radius: 10px !important;
}

.character-uploader .adm-image-uploader-cell {
	border-radius: 50% !important;
}

.adm-image-uploader-cell-loading {
	padding: 10px 10px 13px !important;
	align-items: flex-end !important;
	justify-content: flex-end !important;
}

.adm-spin-loading {
	--size: 16px !important;
}

.adm-image-uploader-cell-mask-message {
	display: none !important;
}

.adm-image.adm-image-uploader-cell-image:after {
	content: "";
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	background: rgba(0, 0, 0, 0.1);
	/* filter: blur(30px); */
}

.adm-image-uploader-cell-mask {
	background-color: rgba(38, 50, 56, 0.5) !important;
}

.adm-image-uploader-cell-delete {
	position: absolute;
	top: auto !important;
	bottom: 8px;
	right: 8px !important;
	background-color: rgba(0, 0, 0, 0.7) !important;
	border-radius: 50% !important;
	font-size: 9px !important;
	color: #fff !important;
	font-weight: bold !important;
	display: flex;
	justify-content: center;
	align-items: center;
}

.character-uploader .adm-image-uploader-cell-delete {
	bottom: 14px !important;
	right: 14px !important;
	background-color: var(--red-color) !important;
}

.character-uploader .adm-image-uploader-cell-loading {
	align-items: center !important;
	justify-content: center !important;
}

.adm-image-uploader-cell-delete-icon {
	position: absolute;
	left: auto !important;
	top: auto !important;
}

.adm-list-header {
	--adm-color-weak: #bcbcbc !important;
	font-size: 13px !important;
	font-weight: 500;
	border-bottom: 1px solid rgb(228, 227, 227);
	padding: 10px;
	margin: 0 !important;
}

.circle {
	border-radius: 50%;
}

.adm-selector-item {
	padding: 8px 15px !important;
	background: #f7f7f7 !important;
	border-radius: 8px !important;
	font-weight: 500 !important;
	font-size: 13px !important;
	color: #575d62 !important;
	border: none !important;
}

.selector-with-border .adm-selector-item {
	border: var(--border) !important;
}

.adm-selector-item-active,
.adm-selector-item-multiple-active {
	background: #e0e0e0 !important;
}

.adm-space-horizontal.adm-space-wrap {
	margin-bottom: 0 !important;
}

.selectNoteType .adm-selector-item {
	background: transparent !important;
	padding: 8px 10px !important;
}

.selectNoteType .adm-space-horizontal.adm-space-wrap {
	margin-bottom: -10px !important;
}

.selectNoteType .adm-selector-item-active,
.selectNoteType .adm-selector-item-multiple-active {
	background: linear-gradient(
			93.97deg,
			rgba(255, 255, 255, 0.2) 0.16%,
			rgba(255, 255, 255, 0) 53.15%
		),
		#58b368 !important;
	box-shadow: 0px 4px 15px rgba(88, 179, 104, 0.4) !important;
	border-radius: 8px !important;
	font-weight: 600 !important;
	font-size: 12px !important;
	color: #ffffff !important;
}

.adm-list-remove-bottom .adm-list-item:last-child .adm-list-item-content {
	border-bottom: none !important;
}

.adm-list-body-inner .adm-list-item:last-child .adm-list-item-content {
	border-bottom: none !important;
}

.adm-nav-bar-left {
	max-width: 100%;
}

.text-limit {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.adm-input {
	--background-color: #fff !important;
}

.adm-input {
	background: #ffffff;
	border: 2px solid #e8e8e8;
	box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.02);
	border-radius: 10px;
	padding: 11px 15px;
}

.adm-input-element {
	text-align: initial !important;
}

.adm-input-element,
.adm-text-area-element {
	color: #575d62 !important;
	font-weight: 600 !important;
	font-size: 16px !important;
}

.adm-input-element::placeholder,
.adm-text-area-element::placeholder {
	----placeholder-color: #bdc0c3 !important;
	font-weight: 700 !important;
	font-size: 16px !important;
	line-height: 22px !important;
}

.adm-stepper .adm-stepper-input {
	min-height: 50px;
}

.adm-stepper-minus,
.adm-stepper-plus {
	height: 50px !important;
	width: 50px !important;
	border-radius: 10px !important;
}

.adm-form .adm-list-item-content-main {
	padding: 7px 0 !important;
}

.adm-form .adm-list-item-content {
	padding-right: 0 !important;
}

.adm-form .adm-form-footer {
	padding: 15px 0px !important;
}

.adm-text-area {
	padding: 10px;
	background: #ffffff;
	border: 1px solid #e8e8e8;
	box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.02);
	border-radius: 10px;
}

.adm-search-bar {
	----placeholder-color: #bdc0c3 !important;
	font-weight: 500;
	font-size: 12px;
}

.adm-search-bar .adm-search-bar-input-box {
	border-radius: 8px !important;
	border: solid 1px #e8e8e8 !important;
	box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.02);
}

.adm-search-bar .adm-search-bar-input-box .adm-search-bar-input.adm-input {
	border-radius: 0 8px 8px 0 !important;
	border: none;
}

.adm-search-bar .adm-search-bar-input-box {
	--background: #fff !important;
}

.adm-search-bar {
	--height: 42px !important;
}

.adm-search-bar-active .adm-search-bar-input-box {
	border: 1px solid #58b368 !important;
	box-shadow: 0px 5px 10px rgba(88, 179, 104, 0.2) !important;
}

.adm-search-bar-active .adm-search-bar-input-box-icon {
	color: #575d62 !important;
}

.adm-search-bar-active .adm-input-clear {
	color: #575d62 !important;
}

.searchNoteBox .adm-list-item-content {
	padding: 20px 15px;
}

.searchNoteBox .adm-list-item-content-main {
	font-weight: 500;
	font-size: 13px;
	color: #263238;
	padding: 0;
}

.searchNoteBox .adm-list-item-description {
	font-weight: 500;
	font-size: 11px;
	color: #ababab;
}

.searchNoteBox .adm-list-item-description p {
	margin-bottom: 0;
}

.users-search-box .adm-list-item-content {
	padding: 5px 20px;
}

.adm-empty-description {
	font-weight: 600 !important;
	font-size: 13px !important;
	color: #bcbcbc !important;
}

.adm-tabs-tab {
	font-size: 13px !important;
	font-weight: 600 !important;
	color: #bcbcbc;
}

.adm-tabs-tab-active,
.adm-tabs-tab-line {
	--adm-color-primary: #fea419 !important;
}

.adm-tabs-header {
	--adm-border-color: rgba(232, 232, 232, 0.7) !important;
}

.notification-list-tabs .adm-capsule-tabs-header {
	padding-top: 2px !important;
}

.notification-list-page {
}

.course-list-item .adm-list-body {
	background: transparent !important;
}

.course-list-item .adm-list-item {
	margin-bottom: 10px !important;
	background: #ffffff;
	border: 2px solid #f5f5f5;
	border-radius: 10px;
	padding-top: 18px;
	padding-bottom: 18px;
	padding-left: 18px !important;
}

.notificatios-list-item .adm-list-item {
	border: none;
	background-color: transparent !important;
	border-radius: 0 !important;
}

.notificatios-list-item .list-item:nth-child(even) {
	background-color: #fafafa;
	border-radius: 0;
}

.list-with-status .adm-list-item {
	padding-bottom: 50px;
}

.course-list-item .adm-list-item-content-main {
	padding: 0 !important;
}

.course-list-item .adm-list-item-content {
	align-items: center !important;
	padding-right: 18px !important;
}

a {
	--adm-color-primary: var(--primary-color) !important;
}

.adm-checkbox-content {
	font-weight: 500;
	font-size: 13px !important;
	color: #575d62;
}

.edit-course-page .adm-capsule-tabs-header {
	padding: 12px 20px !important;
}

.edit-course-page .adm-capsule-tabs-tab-list {
	background: #f7f7f7;
	border-radius: 10px;
	padding-top: 5px;
	padding-bottom: 5px;
}

.edit-course-page .adm-capsule-tabs-tab {
	font-weight: 600;
	font-size: 12px;
	color: #575d62;
	letter-spacing: 0.4px;
}

.edit-course-page .adm-capsule-tabs-tab-active {
	color: #fff;
	background: linear-gradient(
			92.68deg,
			rgba(255, 255, 255, 0.15) 0%,
			rgba(255, 255, 255, 0) 52.24%
		),
		#587dee;
	/* MediumBlueButtonShadow */

	box-shadow: 0px 4px 15px rgba(88, 125, 238, 0.4);
	border-radius: 8px;
}

.aligned-form .adm-list-item {
	padding-left: 0px !important;
}

.adm-page-indicator {
	--active-dot-color: var(--primary-color) !important;
	--active-dot-size: 30px !important;
	--dot-size: 6px !important;
	--dot-border-radius: 3px !important;
}

.list-of-languages-for-media .adm-list-item-content {
	padding-right: 20px !important;
	padding-left: 20px !important;
}

.subtitle-words-popover .adm-popover-inner {
	border-radius: 50px;
	background: #ffffff;
	box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.05);
}

.subtitle-words-popover .adm-popover-arrow {
	display: none;
}

.wordEditForm .adm-list.adm-list,
.adjustTimeForm .adm-list.adm-list {
	--padding-left: 0 !important;
	--padding-right: 0 !important;
}

.wordEditForm .adm-list-item-content-main {
	padding: 0 !important;
}

.adjustTimeForm .adm-list-body-inner {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.adjustTimeForm .adm-list-item-content {
	position: relative;
}

.adjustTimeForm .adm-list-item-content-extra {
	position: absolute;
	right: 12px;
}

.adjustTimeForm .adm-selector-item {
	background-color: #fff !important;
	border: 1px solid #e8e8e8 !important;
	border-radius: 10px !important;
}
.adjustTimeForm .adm-selector-item-active {
	background: #e8e8e8 !important;
	/* Gray4 */

	border: 1px solid #e8e8e8 !important;
	border-radius: 10px !important;
}

.side-menu-list .adm-list-item-content-main {
	padding: 0 !important;
}

.unit-popover {
	z-index: 99 !important;
}
.unit-popover .adm-popover-arrow {
	display: none;
}

.admin-details .adm-list-item-content-extra {
	flex: unset !important;
}

.apply-subscription-coupon .adm-form-item-label {
	line-height: 40px !important;
}

.vocab-list-page .adm-search-bar {
	--height: 52px !important;
}

.no-arrow .adm-list-item-content-arrow {
	display: none !important;
}

.no-style-button {
	border: none;
	padding: 0;
	background: transparent;
}

.iran-sanction {
	text-align: center;
	display: flex;
	flex-direction: column;
	padding: 20px;
	font-size: 16px;
	direction: ltr;
	justify-content: center;
	align-items: center;
	height: 100vh;
}

.video-player-buffer-loading .adm-spin-loading {
	--size: 48px !important;
}

.quizGuidePopover .adm-popover-inner {
	width: 500px;
	max-width: calc(100vw - 100px);
	padding-top: 10px;
}

.full-with {
	width: 100%;
}

.chat-audio-player .adm-slider-thumb {
	height: 14px !important;
	width: 14px !important;
}

.chat-audio-player .adm-slider-thumb-container {
	display: flex !important;
	align-items: center !important;
	justify-content: center !important;
}

.ql-align-right {
	text-align: right;
}
.ql-direction-rtl {
	text-direction: rtl;
}
