.root.success {
  background: #EEFCE9;
  border: 1px solid rgba(88, 179, 104, 0.4);
  box-shadow: 0px 4px 10px rgba(88, 179, 104, 0.1);
  border-radius: 10px;
  padding: 15px 20px;
}

.root.error {
  padding: 15px 20px;
  background: #FCEDE9;
  border: 1px solid rgba(245, 92, 71, 0.4);
  box-shadow: 0px 4px 10px rgba(234, 78, 44, 0.1);
  border-radius: 10px;
}

.root.warning {
  padding: 15px 20px;
  background: #FCF8E9;
  border: 1px solid rgba(179, 148, 88, 0.4);
  box-shadow: 0px 4px 10px rgba(179, 164, 88, 0.1);
  border-radius: 10px;
}

.iconWrapper {
  border-radius: 50%;
  width: 22px;
  height: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 3px;
}

.title {
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
}

.root.success .title{
  color: #58B368;
}

.root.error .title{
  color: #F55C47;
}

.root.warning .title{
  color: #D2B23E;
}

.description {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #263238;
}

.root.success .iconWrapper{
  background: #58B368;
}

.root.error .iconWrapper{
  background: #F55C47;
}