.actionSheetRoot {
	transform: translate(0px, 100%);
	transition: transform 400ms ease-in-out;
	transition-property: transform, height;
	position: fixed;
	width: 100%;
	bottom: 0;
	left: 0;
	border: 1px solid #eee;
	border-top-left-radius: 8px;
	border-top-right-radius: 8px;
	z-index: 10000;
	background-color: #fefefe;
	border-radius: 10px 10px 0px 0px;
}

.actionSheetRoot.visible {
	transform: translate(0px, 0%);
}

.hasHeading {
	padding-top: 58px;
}

.overlay {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	width: 100%;
	height: 100vh;
	z-index: 10000;
	background-color: rgba(0, 0, 0, 0.3);
}

.heading {
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-bottom: 1px solid #e8e8e8;
	padding: 15px 15px 10px 20px;
	position: fixed;
	top: 0px;
	left: 0px;
	width: 100%;
}

.headingRtl {
	padding: 15px 20px 10px 10px;
}

.title {
	font-weight: 600;
	font-size: 14px;
	color: #575d62;
	display: flex;
	align-items: center;
	gap: 10px;
}

.inner {
	position: relative;
}

.content {
	overflow-y: auto;
	max-height: calc(90vh - 45px);
	padding: 15px 20px 10px;
}

.closeIconRoot {
	height: 30px;
	width: 30px;
	display: flex;
	align-items: center;
	justify-content: center;
}
