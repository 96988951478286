.root {
    align-items: center;
    flex-direction: row;
    gap: 12px;
}

.countText {
    font-weight: 800;
    font-size: 13px;
    line-height: 18px;
    /* Black 100 */
    color: #263038;
}

.stats{
    flex-direction: row;
    align-items: center;
    gap: 7px;
}