.user {
	display: flex;
	border-bottom: 1px solid #e8e8e8;
	padding: 20px;
}

.noImage {
	margin-right: 10px;
}

.noImageRtl {
	margin-right: 0;
	margin-left: 10px;
}

.noImage svg {
	border-radius: 50%;
}

.root {
	padding: 20px 0;
	height: 100vh;
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.fullname {
	font-weight: 600;
	font-size: 15px;
	line-height: 24px;
	color: #263238;
}

.email {
	font-weight: 500;
	font-size: 10px;
	line-height: 10px;
	color: #ababab;
}

.iconWrapper {
	height: 17px;
}

.iconWrapperRtl {
	margin-right: -12px;
	padding-left: 12px;
}

.title {
	font-weight: 500;
	font-size: 14px;
	line-height: 14px;
	color: #263238;
	padding: 15px 0 12px;
}

.titleLink {
	padding: 3px 0 0;
}

.content {
	margin-top: 15px;
	padding: 0 20px 20px 8px;
	flex: 1;
	overflow-y: auto;
}

.logout .title {
	color: #f55c47;
	font-weight: 500;
	font-size: 15px;
}

.appInfo {
	bottom: 20px;
	padding: 20px 20px 0;
	width: 100%;
}

.appTitle {
	font-weight: 600;
	font-size: 12px;
	line-height: 14px;
	color: #575d62;
	padding-bottom: 5px;
}

.appVersion {
	font-weight: 500;
	font-size: 10px;
	line-height: 14px;
	color: #ababab;
}

.link {
	color: #263238;
	display: block;
	padding: 12px 0;
}

.avatar {
	height: 40px;
	width: 40px;
	border-radius: 10px;
}
