.root {
	align-items: center;
	padding: 0px 20px;

	height: 80px;
	background: rgba(254, 164, 25, 0.15);
	border-radius: 10px;
	margin: 0 20px;
	justify-content: space-between;
}

.wrap {
}

.buyIntl {
	padding: 10px 20px;
	text-align: end;
}

.text {
	flex-direction: column;
}

.title {
	font-size: 18px;
	color: #263038;
}

.desc {
	color: #575d62;
}

.actionBtn {
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 10px 15px;
	gap: 5px;
	height: 39px;
	background: #fea419;
	border-radius: 8px;
}

.actionBtn2 {
	border-radius: 8px;
	background: #f1f1f1;
}

.btnText {
	font-weight: 900;
	font-size: 14px;
	line-height: 19px;
	color: #ffffff;
}

.btnText2 {
	font-size: 14px;
	line-height: 19px;
}
